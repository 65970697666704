.svg {
    animation-delay: 0ms;
    animation: 1s ease-in-out 0s 1 normal forwards running animation-spinner;
    opacity: 0;
}
.svg__circle {
    fill: none;
    stroke-dasharray: 60;
    stroke-dashoffset: inherit;
    stroke-linecap: round;
    stroke-width: 1.5;
    stroke: var(--cla-color-blue-700);
}

@keyframes animation-spinner {
    0% {
        transform: rotate(50deg);
        opacity: 0;
        stroke-dashoffset: 60;
    }
    100% {
        transform: rotate(230deg);
        opacity: 1;
        stroke-dashoffset: 50;
    }
}