.section-header-container {
    color: #2e334e;
    font-size: 14px;
    padding-bottom: 40px;
    display: flex
}

.section-header-title {
    padding-top: 10px;
    flex: 1;
}

.MuiPaper-root {
    background-color: gray;
}


.add-combination-drawer-wrapper {
    display: flex;
}


.close-btn-wrapper {
    height: 10%;
}


.add-combination-container {
    align-self: center;
    flex: 2 0 20%;
    display: flex;
    justify-content: flex-end;
    padding: 30px
}

.add-combination-buttons {
    padding-left: 5px;
    padding-right: 5px;
}

.add-combination-drawer-content-wrapper {
    padding: 0px 30px 30px 30px;
}

.add-combination-drawer-content-field {
    padding-top: 10px;
    padding-bottom: 10px;
}