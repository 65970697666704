.communcation-container {
  padding: 0 23px;
  padding-bottom: 23px;
  margin-top: 20px;
}

.communcation-text {
  font-weight: 400;
}

.communcation-main-content {
  margin-top: 15px;
}

.communcation-main-content > div {
  margin-top: 0;
}

.field__wrapper {
  display: flex; 
  justify-content: center; 
  width: 100%;
}