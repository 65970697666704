
.cla-select {
    font-size: 1rem;
    height: auto;
    font-weight: 600;
    line-height: 1.5; 
}

.cla-select .render-values {
    white-space: break-spaces;
}

.cla-select .truncate-values {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cla-select .tooltip-icon {
    margin-left: 7px;
    width: 19px;
    height: 19px;
    color: #2E334E;
}

.cla-item div {
    padding: 4px 2px;
    white-space: break-spaces;
}

.cla-select .placeholder {
    color: #cbcbcb;
}
