.datepicker {
    width: 162px;
    margin-left: 15px;
}

.datepicker > div > div {
    height: 40px;
    background-color: rgba(0, 0, 0, 0);
}

.datepicker > div > div > div > button {
    padding: 0;
}

.datepicker button > img {
    filter: invert(10%) sepia(24%) saturate(980%) hue-rotate(193deg) brightness(50%) contrast(92%);
}

.datepicker fieldset:first-of-type {
    border-color: var(--cla-color-blue-900);
}

.datepicker ::placeholder {
    color: var(--cla-color-blue-900);
    opacity: 1;
}

.flex {
    margin-top: -15px;
}

.duedate {
    color: var(--cla-color-gray-700);
    font-size: 0.92rem;
}

.success {
    margin-bottom: 69px !important;
    font-size: 0.92rem;
    color: var(--cla-color-gray-700);
}