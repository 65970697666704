
.collapse-icon-wrapper {
    display: flex;
    align-items: center;
    padding-right: 15px;;
}
.column {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.first-auditarea-column {
    width: 37%;
    padding: 34px 0px;
}
.second-auditarea-column {
    width: 60%;
    padding: 34px 0px;
}

.second-auditarea-column>.highlight-modified {
    border-style: dashed;
    border-width: 4px;
    border-color: red;
}

tbody>.highlight-modified {
    border-style: dashed;
    border-width: 4px;
    border-color: red;
}

.comment-box-button-wrapper {
    width: auto !important;
    padding: 8px;
}

.auditarea-wrapper, .comment-wrapper, .scotabd-wrapper {
    width: 100%;
}
.row-border {
    border-top: 1px solid #c6c6c6;
}

.first-scotbd-column {
    width: 35%;
}
.second-scotbd-column {
    width: 62%;
}

.scotabd-row {
    padding-top: 24px;
    padding-bottom: 24px;
}

.wrap-text { 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.field__wrapper,
tbody > tr > td > div {
   padding: 0 !important;
}
