.checkbox-group .tooltip-icon {
    margin-left: 7px;
    width: 13px;
    height: 13px;
    color: #2E334E;
}

.checkbox-group .container-list {
    margin-top: 12px;
}

.checkbox-group .container-list .list-wrapper { 
    min-width: 150px;
}

.checkbox-group .container-list .list-wrapper label {
    align-items: flex-start;
}

.checkbox-group .container-list .list-wrapper span {
    padding-top: 0px;
}

.checkbox-group .container-list .list-wrapper .other-wrapper {
    flex-direction: row;
    display: flex;
}

.checkbox-group{
    width: 100%;
}