.versionContainer {
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;

    color: #565656;
}

.versionHeader {
    display: flex;
    justify-content: space-between;
    margin-top: -21px;
}

.versions {
    display: flex;
    width: 40%;
    justify-content: flex-end;
}

.startingVersion {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.currentVersion {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.content {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 50px;
}

.versionContent {
    max-height: 100%;
}

.versionItem {
    max-height: 255px;
    padding: 35px 0;
    display: grid;
}

.descriptionContainer {
    width: 95%;
    margin: auto;
    margin-top: 24px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 2px solid #E7E6E6;
}

.contrastContainer {
    width: 95%;
    margin: auto;
    padding-top: 25px;
    padding-bottom: 25px;
}

.versionItem:first-child {
    padding-top: 0;
}

.versionItem:last-child {
    padding-bottom: 0;
}

.versionTitle {
    font-family: 'Georgia';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    color: #2E334E;
    padding-left: 8px;
}

.versionNumber {
    font-weight: 600;
    padding-bottom: 12px;
}

.versionDescription {
    font-weight: 400;
}