.textarea-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.cppp-comment-textarea {
  width: 90%;
  margin-right: 25px;
  margin-top: 5px;
}

.cppp-comment-section {
  padding: 0 0 0 0;
}

.field__wrapper_noalign {
  display: flex; 
  justify-content: center; 
  width: 100%;
}