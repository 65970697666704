.vf-section-table-label-wrapper {
  padding: 10px;
}

.vf-section-table-label {
  color: #2e334e !important;
  white-space: 'normal';
  width: 'max-content';
}

.vf-section-formula-label {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #595959;
}

.vf-section-formula-value {
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
}

.field_wrapper_locked_tooltip_title {
  display: flex;
  flex-direction: row;
  padding: .6rem;
}

.person {
  --avatar-size: 2.8rem;
  color: #565656;
}

.vf-section-table-container {
  overflow: auto;
}