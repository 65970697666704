.section-header-container {
    color: #2e334e;
    font-size: 14px;
    padding-bottom: 40px;
    display: flex
}

.section-header-title {
    padding-top: 10px;
    flex: 1;
}

.section-header-expand-all {
    margin-left: auto;
}

.subsection-content {
    padding-top: 30px;
}

.sub-section-container {
    padding-top: 10px;
    padding-bottom: 10px;
}

.sub-section-header-title {
    padding-top: 20px;
    color: #2e334e;
    font-size: 14px;
}

.sub-section-content-empty {
    color: #8F8F8F;
    font-size: 14px;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 40px;
}

.expand_all_link,
.expand_all_link:hover {
    font-size: 11px;
    font-weight: 600;
    font-family: 'museo-sans';
    text-decoration: none;
    color: #2E334E;
}

.box-row {
    display: flex;
    flex-direction: row;
}

.box-column-styles {
    display: flex;
    flex-direction: column;
}

.cod-main-body {
    border: 1px solid gray;
}

.cod-header-row {
    background-color: #f6f6f6;
    border: 1px solid lightgray;
    display: grid;
    grid-template-columns: 25% 25%;
    justify-items: left;
    padding: 20px;
}

.cod-body-row {
    display: grid;
    grid-template-columns: 25% 25%;
    justify-items: left;
    padding: 20px;
}

.cod-header-cell {
    font-weight: 700;
    font-size: 16px;
    line-height: 16.8px;
    font-family: 'museo-sans';
    color: #2e334e;
    border: none;
}

.cod-body-cell {
    align-self: center;
    border-bottom: none;
    font-size: 14px;
    line-height: 16.8px;
    font-family: 'museo-sans';
    border: none;
}

.cod-written-cell {
    padding-top: 5px
}

.cod-wrap-cell {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
}

.cood-main-body {
    border: 1px solid gray;
}

.cood-header-row {
    background-color: #f6f6f6;
    border: 1px solid lightgray;
    display: grid;
    grid-template-columns: 15% 25% 20% 20% 20%;
    justify-items: left;
    padding: 20px;
}

.cood-body-row {
    display: grid;
    grid-template-columns: 15% 25% 20% 20% 20%;
    justify-items: left;
    padding: 20px;
}

.cood-header-cell {
    font-weight: 700;
    font-size: 16px;
    line-height: 16.8px;
    font-family: 'museo-sans';
    color: #2e334e;
    border: none;
}

.cood-body-cell {
    align-self: center;
    border-bottom: none;
    font-size: 14px;
    line-height: 16.8px;
    font-family: 'museo-sans';
    border: none;
}

.cood-wrap-cell {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
}

.cood-merit-cell {
    margin: auto;
}
.cood-personcommunicatedto-cell {
    padding-right: 10px;
}
