.evaluating-container {
  padding: 0 23px;
  padding-bottom: 23px;
  margin-top: 20px;
}

.evaluating-purpose {
  margin-bottom: 10px;
}

.evaluating-objectives {
  margin-bottom: 10px;
}

.evaluating-procedures, .evaluating-common, span, .evaluating-procedures-addition, .evaluating-accumulate-risks {
  font-weight: 400;
}

.evaluating-main-content {
  margin-top: 40px;
}

.evaluating-table {
  margin-top: 15px;
}

.evaluating-section-title {
  font-weight: 700;
}

.evaluating-evaluation {
  margin-top: 40px;
}