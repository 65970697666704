
.collapse-icon-wrapper {
    display: flex;
    align-items: center;
    padding-right: 15px;;
}

.first-questions-column {
    width: 90%;
}
.second-questions-column {
    width: 10%;
}

.questions-row {
    padding-top: 24px;
    padding-bottom: 24px;
}
.row-border {
    border-top: 1px solid #c6c6c6;
}
.expand_all_link, .expand_all_link:hover {
  font-size: 11px;
  font-weight: 600;
  font-family: 'museo-sans';
  text-decoration: none;
   color: #2E334E;
}

.first-form-column {
    padding: 34px 0px;
}
.column {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0;
}
.wrap-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.span-question {
    font-size: 13px;
    color: #2E334E;
    font-weight: 600;
}

