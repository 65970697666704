.container {
  width: 100%;
  color: #000000;
  font-family: 'museo-sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.container-instructions {
  text-align: center;
  color: white;
  font-style: bold;
  font-size: 16px;
  background-color: #c4c4c4;
  font-weight: 600;
  white-space: pre-wrap;
  padding-top: 10%;
  padding-bottom: 10%;
}

.container svg {
  font-family: 'museo-sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
}

.container label {
  color: #000000;
}

.container textarea {
  height: 36vh;
}

.container div[role='radiogroup'] span {
  font-size: 12px;
}

.container textarea::placeholder,
.container input::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.align-right>div>label {
  margin-left: auto;
}

.other-options {
  display: flex;
  flex-direction: row;
  gap: 59px;
  align-items: flex-start;
  margin-top: 22px;
}

.other-options>div:nth-child(2) {
  flex: 1 1 0px;
}

.other-options>div:last-child {
  row-gap: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 35%;
  max-width: 35%;
}

.other-options>div>div>div>div {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}