.popover {
    position: relative;
}

.popover__button:focus {
    outline-color: none;
    outline-width: 0;
}

.popover__content {
    background-color: var(--cla-color-white);
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    margin-top: var(--cla-size-1);
    min-width: max-content;
    max-width: var(--cla-l-max-width);
    position: absolute;
    right: 0;
    z-index: var(--cla-z-index-100);
    padding-top: var(--cla-size-1);
    padding-bottom: var(--cla-size-1);
    padding-left: 0;
    padding-right: 0;
    border-radius: var(--cla-border-radius-md);
}

.popover__content:focus {
    outline-color: none;
    outline-width: 0;
}