.container-header {
  border: 1px solid #e9e9e9;
  background-color: #f7f7f7;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.container-header__text {
  padding: 20.5px;
  margin-left: 39px;
  font-weight: 1000;
  white-space: initial;
}

.custom-table {
  padding: 10px 0px 0px 45px;
  margin-left: 50px;
  width: calc(100% - 50px);
  margin-bottom: 30px;
}

.table-highlighted {
  border: dashed;
  border-width: 4px;
  border-color: red;
}

.custom-table thead {
  border: 1px solid #97979740;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-color: rgb(233, 233, 233);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(233, 233, 233);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(233, 233, 233);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(233, 233, 233);
  border-left-style: solid;
  border-left-width: 1px;
}

.custom-table__column, .custom-table__row {
  padding: 10px 0px;
  font-size: 18px;
  font-weight: 600;
}
.designed-row {
  padding-right: 97px;
}
.custom-table .designed-col .custom-table__column {
  margin-left: 5px;
  margin-right: 80px;
}
.custom-table__column, .custom-table__row span {
  font-weight: 600;
  margin-left: 20px;
}

.custom-table__column span {
  font-weight: 1000;
  font-size: 18px;
  margin-left: 39px;
}

.custom-table__column svg {
  position: relative;
  top: 4px;
}

.custom-table .custom-content-text-field {
  margin-bottom: 0;
  width: calc(100% - 16px);
  margin-left: 16px;
  border-bottom: 2px solid rgb(233, 233, 233);
  padding-bottom: 35px !important;
}
.custom-table tr:last-child .custom-content-text-field {
  border-bottom: none;
}
.custom-table__no-value-label {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 40px;
  font-weight: 300;
}
.custom-table__no-value-label span {
  font-size: 16px;
}

.field__wrapper {
  display: flex; 
  justify-content: center; 
  width: 100%;
}

.keyControlHighlighted {
  border: dashed;
  border-color: red;
  border-bottom: none;
  border-width: 4px;
}

.keyControlContentHighlighted {
  border: dashed;
  border-color: red;
  border-top: none;
  border-width: 4px;
}

.walkthroughHighlighted {
  border: dashed;
  border-color: red;
  border-width: 4px;
  padding-top: 24px;
  padding-right:10px;
}

tr.custom-key-control-tr > td {
  padding: 25px 0;
}