.rte-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Changed to flex-start to avoid issues with alignment */
}

.rte-quill-container {
  width: 100%;
  height: 200px;
}

.rte-quill-container-error {
  width: 100%;
  height: 200px;
}

.rte-quill-container:hover .rte-quill .ql-toolbar,
.rte-quill-container:hover .rte-quill .ql-container,
.rte-quill-container:focus-within .rte-quill .ql-toolbar,
.rte-quill-container:focus-within .rte-quill .ql-container{
  border-color: #34A0A2;
}

.rte-quill-container-error .rte-quill .ql-toolbar,
.rte-quill-container-error .rte-quill .ql-container{
  border-color: red;
}

.rte-quill {
  width: 100%;
  height: 75%;
}

.rte-quill .ql-toolbar {
  background-color: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.rte-quill .ql-container {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.rte-quill .ql-editor {
  font-family: museo-sans;
  background-color: white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow-wrap: anywhere;
}

.rte-quill .ql-editor :focus {
  font-family: museo-sans;
  background-color: white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid red;
}

.rte-quill .ql-tooltip {
  z-index: 10000;
}

.rte-quill .ql-blank{
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.3;
}

.ql-editor.ql-blank::before {
  font-style: normal;
}
.rte-quill, .ql-editor {
  counter-reset: main-list; 
  padding-left: 20px; 
}

.rte-quill > ol, .ql-editor > ol {
  counter-reset: main-list; 
  padding-left: 20px; 
}

.rte-quill > ol > li, .ql-editor > ol > li {
  counter-increment: main-list; 
  margin-left: 0; 
}

.rte-quill > ol > li::before, .ql-editor > ol > li::before {
  content: counter(main-list) ". "; 
  margin-right: 5px;
  font-weight: bold; 
}

.rte-quill > ol li ol, .ql-editor > ol li ol {
  counter-reset: nested-list; 
  padding-left: 30px; 
}

.rte-quill > ol li ol > li, .ql-editor > ol li ol > li {
  counter-increment: nested-list; 
  margin-left: 0;
}

.rte-quill > ol li ol > li::before, .ql-editor > ol li ol > li::before {
  content: counter(nested-list, lower-alpha) ". "; 
  margin-right: 5px;
}

.rte-quill > ol li ol li ol, .ql-editor > ol li ol li ol {
  counter-reset: roman-list; 
  padding-left: 40px; 
}

.rte-quill > ol li ol li ol > li, .ql-editor > ol li ol li ol > li {
  counter-increment: roman-list; 
  margin-left: 0;
}

.rte-quill > ol li ol li ol > li::before, .ql-editor > ol li ol li ol > li::before {
  content: counter(roman-list, lower-roman) ". "; 
  margin-right: 5px;
}
/* Add support for ql-indent classes */
.rte-quill > ol > li.ql-indent-1, .ql-editor > ol > li.ql-indent-1 {
  padding-left: 30px;
}