.rb-main-wrapper {
    border: solid 1px #979797;
    background-color: white;
    border-radius: 4px;
}

.rb-main-header{
    padding: 15px;
}

.rb-header-message{
    font-weight: 600;
}

.rb-main {
    padding: 15px 50px 15px 50px;
    display: flex;
    flex: auto;
    flex-direction: column;
    height: 100%;
}

.rb-main-content {
    overflow-y: auto;
    height: 350px;
    padding: 0px 15px;
}

.rb-addrow-wrapper {
    display: flex;
    padding: 30px 0px;
}

.rb-addrow-textfield {
    padding-right: 10px;
}

.rb-row-wrapper {
    display: flex;
}

.rb-row-rowlabel {
    padding: 15px 15px 15px 0px;
    flex: 1 0;
}

.rb-row-delete-icon {
    padding: 15px;
}
