.MuiPaper-root {
    background-color: gray;
}

.def-drawer-container {
    margin: 15px;
    background-color: #ffff;
    border-radius: 5px;
}

.def-drawer-wrapper {
    display: flex;
}

.def-header-container {
    padding: 15px 15px 15px 35px;
    flex: 2 0 70%;
}

.def-header-title-text {
    font-family: 'Georgia';
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 19px;
    padding-top: 10px;
    color: #2e334e !important;
    cursor: default;
}

.def-header-text {
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 13px;
    min-height: 13px;
}

.close-btn-wrapper {
    height: 10%;
}

.def-header-title {
    padding: 25px;
    font-size: 17px;
    font-family: 'Georgia';
    font-weight: bold;
    cursor: default;
    padding: 60px 0px 20px 25px;
}

.def-button-container {
    align-self: center;
    flex: 2 0 20%;
    display: flex;
}

.def-buttons {
   padding-left: 5px;
   padding-right: 5px;
}

.column-header-wrapper {
    display: flex;
    background-color: #f6f6f6;
    border-top: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    height: 45px;
}

.column-header-text-column1 {
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: 1000;
    font-size: 13px;
    line-height: 13px;
    color: #2E334E;
    align-self: center;
    width: 50%;
    padding-left: 35px;
}

.column-header-text-column2 {
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: 1000;
    font-size: 13px;
    line-height: 13px;
    color: #2E334E;
    align-self: center;
    width: 50%;
    padding-left: 12%;
}

.def-row-add-button {
    padding-left: 30px;
    padding-bottom: 15px;
}

.def-row-wrapper {
    display: flex;
}

.def-row-text {
    flex: 2;
    width: 60%;
    padding: 15px 15px 15px 35px;
}

.def-row-dropdown {
    flex: 1;
    width: 30%;
    padding: 15px;
}

.def-row-delete-icon {
    flex: 0;
    width: 10%;
    padding: 15px;
}