.quill-container {
    width: 85%
}

.fin-state-level-main-content>.highlight-modified {
    border-style: dashed;
    border-width: 4px;
    border-color: red;
}

.fin-state-level-container {
    border: 1px solid rgba(224, 224, 224, 1);
}

.fin-state-level-header {
    display: flex;
    background-color: #f6f6f6;    
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    height: 80px;
}

.fin-state-level-header-text {
    font-family: 'museo-sans';
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 19px;    
    color: #2e334e !important;
    cursor: default;
    width: 33%;
    padding: 10px;
    padding-right: 130px;
}

.fin-state-level-row {
    display: flex;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    height: 180px;
}

.fin-state-level-text-cell{
    padding: 20px;
    width: 33%
}

.fin-state-level-rte-cell {
    padding: 20px;
    width: 33%
}

.field__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
  