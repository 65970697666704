.irfMainWrapper {
    margin: 0 45px;
}

.irfMainWrapper .irfTableContainerWrapper {
    box-shadow: none;
}

.irfSectionWrapper {
    position: relative;
    padding: 10px 0px 10px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'museo-sans';
}

.irfSection {
    background-color: white;
    width: 100%;
    border: 1px solid rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.irfSection .sectionHeaderWrapper {
    font-family: 'Georgia';
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: #2e334e;
}

.irfSection .sectionHeaderWrapper .sectionHeader {
    display: flex;
    gap: 6px;
    align-items: center;
    flex-grow: 1;
}

.irfSection .sectionHeaderWrapper div:last-child {
    margin-right: -16px;
}

.irfTableContainer {
    background: none;
}

.irfTableWrapper {
    min-width: 500px;
}

.irfTableWrapper tr:first-child>th {
    border-bottom: unset !important;
    padding-left: unset;

    font-family: 'Georgia';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;

    color: #2E334E;
}

.irfTableWrapper tr>th {
    padding-left: unset;
}

.irfTableWrapper tr>th>a {
    font-family: 'museo-sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;

    color: #34A0A2;
}

.irfTableWrapper tr:nth-last-child(1)>th {
    border-bottom: unset !important;
}