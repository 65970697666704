.EACSubsectionTitle {
    margin-bottom: 12.75px;
    font-family: Georgia;
    font-size: 20.5px;
    font-weight: 1000;
}

.EACParagraph {
    color: #333;
    font-size: 16.4px;
    font-family: 'museo-sans';
    font-weight: 600;
    letter-spacing: -0.15px;
    line-height: 25.5px;
}

.EACFirstParagraph {
    margin-bottom: 55px;
}

.EACSecondParagraph {
    margin-bottom: 30px;
}

.EACRadioLabel {
    font-family: 'museo-sans';
    font-size: 18px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
}
