:root {
    --cla-size-0\/5: 0.25rem;
    --cla-size-1: 0.5rem;
    --cla-size-2: 1rem;
    --cla-size-2\/25: 1.25rem;
    --cla-size-3: 1.5rem;
    --cla-size-4: 2rem;
    --cla-size-5: 2.5rem;
    --cla-size-6: 3rem;
    --cla-size-7: 3.5rem;
    --cla-size-8: 4rem;
    --cla-size-9: 4.5rem;
    --cla-size-10: 5rem;
    --cla-size-12: 6rem;
    --cla-l-max-width: 71.25rem;
    --cla-l-sidebar-width: 13.5rem;
    --cla-l-linelength-width: 36rem;
    --cla-z-index-0: 0;
    --cla-z-index-100: 100;
    --cla-z-index-200: 200;
    --cla-z-index-300: 300;
    --cla-z-index-400: 400;
    --cla-z-index-500: 500;
    --cla-z-index-top: 99999;
    --cla-z-index-bottom: -100;
    --cla-font-family-georgia: 'Georgia';
    --cla-font-family-museo: 'museo-sans';
    --cla-font-size-11: 0.688rem;
    --cla-font-size-12: 0.75rem;
    --cla-font-size-14: 0.875rem;
    --cla-font-size-16: 1rem;
    --cla-font-size-18: 1.125rem;
    --cla-font-size-24: 1.5rem;
    --cla-font-size-28: 1.75rem;
    --cla-font-size-32: 2rem;
    --cla-font-size-40: 2.5rem;
    --cla-font-size-base: 12px; /* Base font size*/
    --cla-border-width-sm: 1px;
    --cla-border-width-md: 2px;
    --cla-border-width-lg: 4px;
    --cla-border-width-xl: 8px;
    --cla-border-radius-md: 4px;
    --cla-border-radius-lg: 8px;
    --cla-border-radius-xl: 20px;
    --cla-border-radius-round: 50%;
    --cla-border-radius-full: 9999px;
    --cla-font-weight-normal: 500;
    --cla-font-weight-bold: 600;
    --cla-font-weight-medium: 700;
    --cla-color-white: #FFFFFF;
    --cla-color-black: #000000;
    --cla-color-riptide-475: #6BAEB4;
    --cla-color-cyan-500: #76D2D3;
    --cla-color-cyan-600: #49BFC1;
    --cla-color-blue-700: #42526E;
    --cla-color-blue-800: #2E334E;
    --cla-color-blue-900: #1E2133;
    --cla-color-gray-container: #F6F6F6;
    --cla-color-gray-350: #565656;
    --cla-color-gray-500: #979797;
    --cla-color-gray-700: #4B4B4B;
    --cla-color-red: #FD4747;
    --cla-icon-close-color: rgba(0, 0, 0, 0.54);
    --cla-icon-size-sm: 10px;
    --cla-icon-size-md: 18px;
    --cla-icon-size-lg: 21px;
    --cla-icon-size-xl: 24px;
}
