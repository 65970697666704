mgt-person {
    --avatar-size: 100px;
}

.engagementLeaderContainer {
    display: flex;
    color: #FFF;

    margin-right: 30px;
}
.engagementLeaderContainer a {
    color: #FFF;
}

.leaderImage {
    width: 100px;
    height: 100px;

    border-radius: 110px;
}

.containerRight {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-left: 15px;
}

.textName {
    font-size: 20px;
}

.textTitle {
    font-size: 14px;
    margin-top: 5px;
}

.iconContainer {
    display: flex;
    margin-top: 10px;
}

.iconCSS {
    margin-right: 10px;
}
