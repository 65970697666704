.trialBalanceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.trialBalanceDropdownSelectContainer {
    /* display: flex; */
    width: 45%;
    align-items: center;
}

.trialBalanceDropdownSelectContainerHighlighted {
    border-style: dashed;
    border-width: 4px;
    border-color: red;
    padding: 18px 12px;
}

.trialBalanceExportToCSVContainer {
    display: flex;
    width: 15%;
    justify-content: flex-end;
}