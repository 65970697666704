.flex {
    display: flex;
}

/* Direction */

.flex--row {
    flex-direction: row;
}

.flex--column {
    flex-direction: column;
}

/* Align Items */
.flex--baseline {
    align-items: baseline;
}

.flex--center {
    align-items: center;
}

.flex--justify-start {
    justify-content: start;
}

.flex--justify-between {
    justify-content: space-between;
}

.flex--justify-center {
    justify-content: center;
}