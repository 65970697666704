.rte-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.rte-quill-container {
  width: 100%;
  height: 200px;
}

.rte-quill-container-error {
  width: 100%;
  height: 200px;
}

.rte-quill-container:hover .rte-quill .ql-toolbar,
.rte-quill-container:hover .rte-quill .ql-container,
.rte-quill-container:focus-within .rte-quill .ql-toolbar,
.rte-quill-container:focus-within .rte-quill .ql-container{
  border-color: #34A0A2;
}

.rte-quill-container-error .rte-quill .ql-toolbar,
.rte-quill-container-error .rte-quill .ql-container{
  border-color: red;
}

.rte-quill {
  width: 100%;
  height: 75%;
}

.rte-quill .ql-toolbar {
  background-color: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.rte-quill .ql-container {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.rte-quill .ql-editor {
  font-family: museo-sans;
  background-color: white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow-wrap: anywhere;
}

.rte-quill .ql-editor :focus {
  font-family: museo-sans;
  background-color: white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid red;
}

.rte-quill .ql-tooltip {
  z-index: 10000;
}

.rte-quill .ql-blank{
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.3;
}

.ql-editor.ql-blank::before {
  font-style: normal;
}