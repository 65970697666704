.cppp-header-select {
  display: flex;
  flex-direction: column;
  padding-top: 5px 0 10px 0 !important;
}

.cppp-select {
  width: 160px;
}

.cppp-comment-section {
  padding: 20px 0 0 0;
  margin: -10px 0 0 0;
}

.cppp-comment-textarea {
  width: 80%;
}

.cppp-type-level-placeholder {
  background-color: #C4C4C4;
  color: white;
  height: 80px;
  text-align: center;
  vertical-align: middle;
  line-height: 80px;
  margin: 30px;
}

.cppp-select-label {
  padding-bottom: 10px;
}

.cppp-header-container {
  display: flex;
  flex-direction: column;
}