.text-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.custom-header {
  display: flex;
  flex-direction: column;
  margin-left: 13px;
}