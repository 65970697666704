.message-box {
    background-color: #ffd7d4;
    color: #bc2142;
    border: 0.06rem solid #bc2142;
    border-radius: 0.25rem;
    box-shadow: none;
    width: 40rem;
    margin-top: -3.1rem;
    padding: 0;
    font-size: .9.5rem;
    font-weight: 400;
    line-height: 1.4375rem;
    align-items: center;
}

.message-row {
    padding: 0;
    text-align: center;
    margin: 0;
}