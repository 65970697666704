.dialogPaperContainer {
    max-width: 603px !important;
    width: 100%;
    max-height: 271px !important;
    height: 100%;
}

.warningMessage {
    margin-top: 29px !important;
    font-family: 'museo-sans', sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px !important;

    color: #BC2142;
}

.confirmButton {
    padding: '9px 25px 8.53px 25px';
    min-width: '92px';
}

.cancelButton {
    min-width: 92px;
}