.headerMainContainer {
    display: grid !important;
    margin: 0 !important;
    padding: 0 !important;
    border-top: 25px solid #2E334E;
    min-width: 650px;
}

.headerLogo {
    height: 40px;
    width: 40px;
}

.headerSubContainer {
    display: grid !important;
    padding: 0;
    margin-left: 18px;
    padding-left: 0px !important;
    grid-template-columns: 98vw 15vw;
}

.headerSubContainer2 {
    display: flex;
    align-items: center;
}

.headerImg {
    background: #2d3b59;
}

.headerContentContainer {
    position: absolute;
    display: flex !important;
    margin: 50px 95px;
    font-family: Georgia;
    color: #F7F7F7;
    width: 250px;
    height: 24px;
}

.headerTextName {
    font-size: 21px;
    line-height: 24px;
    margin-bottom: 5px;
}

.headerTextName2 {
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    margin-bottom: 5px;
}

.headerSubText {
    font-family: 'museo-sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.dropdownPerson {
    z-index: 1;
    cursor: pointer !important;
}

.spanPerson {
    display: flex;
    color: #565656;
}

.person {
    --avatar-size: 40px;
    color: #565656;
}

.spanPerson svg {
    margin-top: 8px
}

.showMenu {
    margin-top: 20px;
    display: block;
    z-index: 0 !important;
}

.hideMenu {
    display: none
}

.showMenu ul li {
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #2D3C59;
}

ul .menuItem {
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #2D3C59;
}

li.menuItem:hover {
    background-color: #76D2D3;
}

.headerItem {
    display: grid;
}

.claHeaderPaper {
    width: 150px;
    left: auto !important;
    right: 35px !important;
    margin-top: 20px;

    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
}
.claNotificationPaper {
    width: 20vw;
    left: auto !important;
    right: 35px !important;
    margin-top: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
}
.claHeaderMenuList {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}