.delete-container {
  margin-left: 10px;
  display: flex;
  align-items: center;
  max-width: 22px;
}

.add-audit-area-container {
  display: flex;
  margin-top: 17px;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.plusIcon {
  color: rgba(125, 210, 211, 1);
  width: 18px !important;
  margin-right: 3px;
}

.plusIconText {
  font-family: "museo-sans";
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.scotabdInnerWrapper {
  display: flex;
}

.auditAreaWrapper {
  flex-direction: column;
}

.auditAreaWrapper>div {
  margin-bottom: 20px;
}

.scotabdWrapper>div {
  margin-bottom: 20px;
}

.scotabdWrapper {
  padding-left: 3px;
  flex-direction: column;
}