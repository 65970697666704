.tblink {
    cursor: pointer;
}

.tblink--valid {
    color: var(--cla-color-gray-500);
    right: 10px;
    cursor: pointer;
}

.tblink--invalid {
    color: var(--cla-color-red);
    cursor: pointer;
}

.tblink__text--invalid {
    color: var(--cla-color-red);
    text-decoration: underline;
}

.tblink__check-icon {
    height: var(--cla-size-2);
    width: var(--cla-size-2);
    margin-right: var(--cla-size-0\/5);
    color: var(--cla-color-gray-500);
}