.headerContainer {
    display: flex;
    width: 100%;
}

.gridContainer {
    display: grid;
    grid-template-columns: 10fr 90px 3fr;
    width: 100%;
    margin-left: 16px;
}

.callapsibleBorder {
    border: none;
    border-top: var(--uoe-datagrid-border-props);
}

.rowTitle{
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.18em;
    font-family: museo-sans, sans-serif;
    line-height: 19.2px;
    outline: none;
}

.rowIcon {
    display: flex;
    justify-content: center;
    padding-left: 17px;
}

.rowButton {
    display: flex;
    justify-content: flex-end;
}

.contentContainer {
    margin-left: 62px;
}

.textfieldContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.numberedText {
    font-family: museo-sans, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #979797;
    margin-bottom: 2rem;
}