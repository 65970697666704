.cb-main-wrapper {
    border: solid 1px #979797;
    background-color: #fff;
    border-radius: 4px;
}

.cb-addcolumn-row {
    display: flex;
    justify-content: flex-end;
}

.cb-main {
    /* padding: 15px 50px 50px 50px; */
    display: flex;
    flex: auto;
    flex-direction: column;
    height: 100%;
}

.cb-main-content {
    overflow-y: auto;
    height: 300px;
    padding: 0px 15px;
}

.cb-main-columns {
    padding-top: 5px;
}

.cb-columns {
    padding-top: 5px;
    padding-bottom: 5px;
}