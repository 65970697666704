/* DIAGNOSTICS */
.DiagAccordionTitle {
    display: flex;
    align-items: center;
}

/* UNDERSTANDING OF ENTITY */
.UOEContainer{
    /* background-color: red; */
    margin: 0;
    margin-left: 18px;
}

.UOEAccordion{
    border: none !important;
}

.UOEAccordion > div:first-child {
    padding: 0;
    padding-left: 10px;
    border: none;
    border-top: 1px solid rgba(224, 224, 224, 1);
    min-height: 76px;
}

.UOEAccordion>div:first-child:hover {
    background-color: #E4F6F6;
}

.flex100{
    display: flex;
    width: 100%;
}