.container {
    color: #000000;
}

.container *:not(svg) {
    font-family: 'museo-sans';
    font-style: normal;
    font-size: 16px;
}

.container label {
    color: #000000;
}

.container .lower-panel div[role=radiogroup] span {
    font-size: 12px;
}

.container div[role=radiogroup]>label:last-child {
    margin-right: 0px;
}

.container textarea::placeholder {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}

.container .lower-panel>div>label {
    margin-left: auto;
}