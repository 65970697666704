.vf-form-title {
  color: #2E334E;
  font-family: Georgia;
  font-size: 18px;
  font-weight: bold;
}

.vf-form-description {
  margin-top: 6px;
  font-family: 'museo-sans';
  color: #595959;
  font-size: 14px;
}


.vf-section-inner {
  background-color: transparent;
  width: 100%;
  height: 100%;
  min-height: 15vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 18px;
  font-weight: 600;
}

.vf-section-inner .free-text {
  white-space: pre-line;
  color: #6d6d6d;
  word-break: break-word;
  text-align: justify;
}

.vf-section-inner .row-field {
  display: flex;
  position: relative;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
}

.vf-section-inner .row-field .MuiSelect-icon {
  color: #4B4B4B;
  width: 1.25em;
  height: 1.25em;
  top: calc(50% - 0.625em);
}

.vf-rendered-field-wrapper {
  position: relative;
  padding: 24px;
}