.label {
    font-family: var(--cla-font-family-georgia);
}

.label-sm {
    font-size: var(--cla-font-size-16);
}

.label-md {
    font-size: var(--cla-font-size-18);
}

.label-lg {
    font-size: var(--cla-font-size-24);
}

.label-medium {
    font-weight: var(--cla-font-weight-medium);
}
