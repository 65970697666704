.Toastify {
    position: relative;
    z-index: 9;
}
.custom-toast-error {
    height: 50px;
    min-height: auto;
    padding: 0;
    position: relative;
    right: -2em;
}

.custom-toast-error .custom-toast__wrapper {
    text-align: center;
}

.custom-toast-error .Toastify__toast-body {
    position: relative;
    top: -2px;
    margin-left: 14px;
}

.custom-toast-error .custom-toast__wrapper-error .custom-toast__text {
    margin-right: 10px; 
    color: #26201f;
    font-weight: 600;
    font-size: 14px;
    position: relative;
    top: 1.5px;
}
.custom-toast-error .custom-toast__wrapper-error svg {
    font-size: 40px;
    background: #fff;
    border-radius: 50%;
    color: #be2949;
    padding: 3px;
}

/** Success class names */
.custom-toast-success .custom-toast__wrapper-success svg {
    font-size: 48px;
    position: relative;
    left: -18.3px;
    top: -3px;
    color: #37d074;
}

.custom-toast-loading {
    width: 54px;
    height: 54px;
    min-height: 54px;
}
.custom-toast-loading .custom-toast__wrapper-loading svg {
    font-size: 48px;
    color: #559add;
}
.custom-toast-loading .custom-toast__wrapper-loading .MuiCircularProgress-root {
    left: -7px;
    position: relative;
    top: -6px;
}


.custom-toast-success {
    width: 54px;
    height: 54px;
    min-height: 54px;
}
.custom-toast-success .custom-toast__wrapper-success .success-icon {
    position: relative;
    left: -15px;
    top: -17px;
    width: 59px;
}