.dropdown-defaultanswers-tab {
    height: 400px;
}

.dropdown-defaultanswers-wrapper {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.dropdown-defaultanswers-header {
    display: flex;
    padding: 10px 10px 30px 0px;
}

.dropdown-defaultanswers-header-label {
    padding-right: 10px;
    font-weight: 600;
}

.dropdown-defaultanswers-header-value {
    font-weight: 900;
}

.dropdown-defaultanswers-button {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}
