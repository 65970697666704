.contextmenu__item {
    font-family: var(--cla-font-family-museo);
    font-size: var(--cla-font-size-12);
    font-weight: var(--cla-font-weight-medium);
    display: grid;
    justify-content: start;
    padding: 5px 10px;
}
.contextmenu__item:hover {
    background-color: var(--cla-color-cyan-500);
}

.contextmenu__paper {
    background-color: var(--cla-color-white);
    z-index: 2;
    border-radius: 4px;
    box-shadow: 0px 1px 6px 0px #7C7B7B80;
}