.fieldset {
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  padding: initial;
  background-color: #fff;
  min-width: 100%;
  margin: 0;
}

.legend {
  float: initial;
  width: initial;
  padding: initial;
  margin: initial;
  font-size: initial;
  line-height: initial;
  margin-left: 16px;
  padding: 0px 4px;
  font-size: 12px;
  color: #595959;
}

.questionGroupInner {
  box-sizing: border-box;
  width: 100%;
  min-height: 15vh;
  padding: 4px 16px;
}

.rowField {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
}

.renderedFieldWrapper {
  box-sizing: border-box;
  padding: 24px;
}