.POPSection {
    margin-top: 35px;
    margin-bottom: 50px;
}

.POPDescription {
    font-family: 'museo-sans';
    font-size: 16.4px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.011em;
    text-align: left;
    margin-bottom: 7px;
}

.POPKeyword {
    font-weight: 600;
}