.select {
    position: relative;
}

.select-button {
    width: 100%;
    border: 0;
    border-color: transparent;
    background-color: transparent;
    padding: var(--cla-size-1);
    display: flex;
    /* Place button text on the left and the expand more icon on the right. */
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--cla-color-gray-700);
}

.select-button--disabled {
    /* !important is needed here reboot.scss from Bootstrap is overriding the not-allowed property */
    cursor: not-allowed!important;
}

.select-button:focus {
    outline-color: none;
    outline-width: 0;
}

.select__options {
    background-color: var(--cla-color-white);
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    margin-top: var(--cla-size-1);
    min-width: max-content;
    max-width: var(--cla-l-max-width);
    position: absolute;
    right: 0;
    z-index: var(--cla-z-index-100);
    padding-top: var(--cla-size-1);
    padding-bottom: var(--cla-size-1);
    padding-left: 0;
    padding-right: 0;
    list-style: none;
    border-radius: var(--cla-border-radius-md);
}

.select__options:focus {
    outline-color: none;
    outline-width: 0;
}

.select__option {
    display: flex;
    font-family: var(--cla-font-family-museo);
    font-size: var(--cla-font-size-16);
    padding: var(--cla-size-1) var(--cla-size-2);
    cursor: pointer;
}

.select__option--selected {
    background-color: var(--cla-color-cyan-500);
}

.select__option--active {
    background-color: var(--cla-color-cyan-500);
}

/**
 * The caret icon to decorate the select trigger button, animated to rotate.
 */
 .select-button__icon {
    margin-left: var(--cla-size-3);
    transform: rotate(0);
    transition: transform 0.3s ease-out;
}

.select-button__icon--reversed {
    transform: rotate(180deg);
}

.select-button__icon--disabled {
    cursor: not-allowed;
}

.select-button__icon--disabled path {
    fill: var(--cla-color-gray-500);
    stroke: var(--cla-color-gray-500);
}
