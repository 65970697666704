.claNotificationPaper {
    width: 20vw;
    left: auto !important;
    right: 35px !important;
    margin-top: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
}
.claNotificationContainer {
    max-height: 30vh;
    min-height: 30vh;
    overflow-y: auto;
    background-color: #fff;
    padding: 4px;
    scroll-behavior: smooth;
}
.claNotificationHeader {
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    padding-left: 16px;
    line-height: 19px;
    color: rgb(46, 51, 78);
    height: 5vh;
    padding-top: 2vh;
    padding-bottom: 2vh;
}
.claNotificationItemHeader {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    margin: 5px 5px;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 10px;
}
.closeIcon {
    cursor: pointer;
    margin: 5px 5px;
}
.claNotificationItemTextContent {
    padding: 0px 5px 0px 5px;
    margin: 0px;
    font-family: 'museo-sans';
    color: #595959;
    line-height: 17px;
    font-size: 14px;
    overflow-wrap: anywhere;
}
.claNotificationItemLink {
    padding: 0px 5px 0px 5px;
    margin: 0px;
    font-family: 'museo-sans';
    color: #0A2E45;
    line-height: 17px;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    overflow-wrap: anywhere;

}
.claNotificationItemDate {
    padding: 5px;
    margin: 0px;
    font-family: 'museo-sans';
    color: #2E334E;
    line-height: 14.4px;
    font-size: 12px;
    font-weight: 400;
    overflow-wrap: anywhere;
}
.claNoNotificationsText {
    margin: 1vh 0vh 0vh 1vh;
}