.heading {
    margin-top: 0;
    margin-bottom: 0;
}

/* Heading size */

.heading--size-1 {
    font-size: var(--cla-font-size-14);
}

.heading--size-2 {
    font-size: var(--cla-font-size-16);
}

.heading--size-3 {
    font-size: var(--cla-font-size-18);
}

.heading--size-4 {
    font-size: var(--cla-font-size-24);
}

/* Heading weight */

.heading--weight-normal {
    font-weight: 500;
}

.heading--weight-medium {
    font-weight: 700;
}

/* Heading font family */

.heading--fontFamily-georgia {
    font-family: var(--cla-font-family-georgia);
}

.heading--fontFamily-museo {
    font-family: var(--cla-font-family-museo-sans);
}
