.custom-table-container {
  padding: 10px 0px 0px 0;
  margin-top: 15px;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  border: 1px solid #e9e9e9;
  background-color: #f7f7f7;
}

thead > th {
  padding: 18px 0 18px 25px;
}

th > span {
  font-size: 1.3em;
  font-weight: 700;
}

.custom-table-key-controls {
  padding-left: 10px;
}

.custom-table-key-control-content {
  padding-left: 10px !important;
}

.custom-table-risk-content {
 padding-left: 25px !important; 
}

.custom-table-design-content, .custom-table-implement-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.custom-table-design-header, .custom-table-implementation-header {
  text-align: center;
}

tr > td > div {
  padding: 25px 0 25px 0;
  font-weight: 600;
  font-size: 1.2em;
}

.custom-content-text-field-container {
  border-bottom: 1px solid #dedede;
}

.custom-content-text-field {
  margin-left: 10px;
  padding-bottom: 18px !important;
}
.has-border {
  border-bottom: 1px solid rgb(222,222,222);
}

.row-highlighted {
  border: dashed;
  border-width: 4px;
  border-color: red;
  border-bottom: none;
}

.table-highlighted {
  border: dashed;
  border-width: 4px;
  border-color: red;
}

.row-highlighted-bottom {
  border: dashed;
  border-width: 4px;
  border-color: red;
  border-top: none;
  border-bottom: dashed !important;
  border-bottom-color: red !important;
  border-bottom-width: 4px !important;
}

tr.custom-key-control-tr > td {
  padding: 25px 0;
}

.walkthroughHighlighted {
  padding: 10px;
  border: dashed;
  border-color: red;
  border-width: 4px;
}

.keyControlCheckBoxHighlighted{
  padding-bottom: 7px;
  border: dashed;
  border-color: red;
  border-width: 4px;
}