.drawer__close-button {
    position: relative;
    margin-right: var(--cla-size-2);
    margin-top: var(--cla-size-2);
}

.drawer__container {
    padding: var(--cla-size-4);
}

.drawer__content {
    background-color: var(--cla-color-white);
    padding: var(--cla-size-4);
}

.drawer__content__icon-container {
    display: flex;
    justify-content: end;
}

.drawer__label {
    margin-bottom: var(--cla-size-2);
}

.drawer__content__actions {
    justify-content: end;
    margin-bottom: var(--cla-size-6);
}

.drawer__content__submit-button {
    margin-left: var(--cla-size-2\/25);
}

.drawer__content__cancel-button {
    margin-left: var(--cla-size-2\/25);
}

.drawer__content__row {
    margin-top: var(--cla-size-4);
    align-items: center;
}

.drawer__content__row__item {
    margin-left: var(--cla-size-2);
}

.drawer__content__row__header {
    align-items: center;
}

.drawer__content__row>*+* {
    margin-left: var(--cla-size-2);
}

.drawer__content__sign-options {
    max-width: 45%;
}

.linkOffIcon {
    cursor: pointer;
    color: black;
    display: flex;
    align-items: center;
    gap: 5px;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.linkOffIcon:hover {
    opacity: 0.7;
}

.linkOffIcon.disabled {
    cursor: default;
    color: rgba(0, 0, 0, 0.7);
    opacity: 0.7;
}