.GLCplaceholderBlock {
  background-color: #c4c4c4;
  color: white;
  height: 80px;
  text-align: center;
  vertical-align: middle;
  line-height: 80px;
}

.GLCtextArea {
  width: 100%;
  margin-bottom: 35px;
}

.GLCTitle {
  font-family: "museo-sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 21.6px;
}

.glc-roll-forward-sucess-container {
  height: 80px; 
}

.glc-roll-forward-sucess {
  font-size: var(--cla-font-size-18);
}