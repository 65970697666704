.filter {
    border: 0;
    border-bottom: 1px solid #2E334E;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    margin-bottom: 9px;
}

.filter__label {
    margin-left: var(--cla-size-1);
    margin-right: var(--cla-size-1);
    font-family: var(--cla-font-family-museo);
    font-size: var(--cla-font-size-14);
    font-weight: var(--cla-font-weight-bold);
    color: var(--cla-color-blue-800);
}

.filter__label--disabled {
    color: var(--cla-color-gray-500);
}

.filter__button--disabled {
    cursor: not-allowed;
}

.filter__content {
    padding-left: var(--cla-size-1);
    font-size: var(--cla-font-size-12);
    white-space: nowrap;
    max-width: 270px;
}

.filter__inputContainer {
    position: relative;
}

.filter__input {
    display: block;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: var(--cla-color-gray-500);
    border-radius: var(--cla-border-radius-md);
    padding-right: var(--cla-size-3);
}

.filter__input:focus {
    border-color: var(--cla-color-cyan-600);
    outline: 0;
}

.filter__input:disabled {
    cursor: not-allowed;
}

.filter__input-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 1.8em;
    width: 1.6em;
    padding-right: var(--cla-size-1);
}

/**
 * The caret icon to decorate the select trigger button, animated to rotate.
 */
 .filter__caret-icon {
    margin-left: 0px;
    transform: rotate(0);
    transition: transform 0.3s ease-out;
}

.filter__button span{
    display:inline-block;
    width: 212px;
    margin-left: -26px;
}


.filter__caret-icon path {
    fill: var(--cla-color-blue-800);
    stroke: var(--cla-color-blue-900);
}

.filter__caret-icon--reversed {
    transform: rotate(180deg);
}

.filter__caret-icon--disabled {
    cursor: not-allowed;
}

.filter__caret-icon--disabled path {
    fill: var(--cla-color-gray-500);
    stroke: var(--cla-color-gray-500);
}

.filter__icon {
    fill: var(--cla-color-blue-800);
}

.filter__icon--disabled {
    fill: var(--cla-color-gray-500);
}

button > svg {
    margin-left: 0px !important;
}