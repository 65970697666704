.data-grid-container {
  width: 100%;
}

.pageButtonAddFormIcon {
  color: #6baeb4 !important;
}

.pageButton {
  background-color: white !important;
  color: #2E334E !important;
  font-family: 'museo-sans' !important;
  min-width: 1% !important;
  padding: 2 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.pageButtonSelected {
  background-color: #6BAEB4 !important;
  color: white !important;
  font-family: 'museo-sans' !important;
  min-width: 1% !important;
  padding: 2 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  margin-left: 3px;
  margin-right: 3px;
}

.MuiTablePagination-selectLabel {
  margin-bottom: 0;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-right: 30px;
  margin-left: 5px;
}

.buttonPageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0px;
}

.footer {
  display: 'flex';
  justify-content: 'center';
  padding-top: 5px;
  border-top: 1px solid rgba(151, 151, 151, 0.25);
}

.ellipses {
  background-color: white !important;
  color: #2E334E !important;
  font-family: 'museo-sans' !important;
  min-width: 1% !important;
  cursor: text !important;
  width: 20px;
  height: 20px;
  padding: 0 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}