.dialogPaperContainer {
    max-width: 603px !important;
    width: 100%;
    max-height: 271px !important;
    height: 100%;
}

.modal-title-bar {
    align-items: center;
    margin-top: 46px;
    margin-bottom: 10px;
    gap: 10px;
}

.modal-title-body-bar {
    align-items: center;
    margin-bottom: 15px;
    gap: 10px;
}

.main-container {
    padding: 25px 20px;
    min-height: 80vh;
}

.content-container {
    gap: 35px;
    padding: 25px 0;
}

.control-container {
    flex-grow: 1;
    min-width: 0;
}

.dropdown-text-overflow{
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-text-values {
    width: 33vw;
}

.control-container div {
    gap: 30px;
}

.smaller-gap-bottom {
    margin-bottom: -12.5px;
}

.smaller-gap-top {
    margin-top: -12.5px;
}

.delete-container {
    align-self: flex-start;
    align-items: flex-start;
    justify-self: flex-end;
    width: 7%;
}

.delete-button {
    align-self: flex-end;
}   

.confirm-button {
    align-self: flex-end;
}

.delete-icon {
    margin-top: -8px;
    margin-left: 4px;
    width: 28px;
    height: auto;
}

.footer {
    border-top: 2px solid #c6c6c6;
    padding-top: 20px;
    padding-left: 15px;
    align-items: center;
    gap: 10px;
}

.footer-title {
    font-family: 'museo-sans';
    margin-right: 20px;
}

.footer-button {
    padding: 10px 25px;
}

.cancel-button { 
    background-color: #F6F6F6;
    color: #4B4B4B 
}

.add-icon {
    color: #6BAEB4 !important;
}

.button-key-control {
    align-self: flex-start;
    font-weight: bold;
    left: -14.5px;
}

.dialog-container {
    margin-top: -5px;
    gap: 15px;
}

.dialog-body-text {
    text-align: start;
    font-size: 14px;
    font-weight: 450;
}

.dialog-body-text-bold {
    text-align: start;
    font-size: 14px;
    font-weight: bold;
}

.dialog-header-text { 
    font-size: 25px;
    margin-bottom: 20px;
    text-align: left;
}

.projectrisks-container {
    gap: 5px;
    flex-wrap: wrap;
}
