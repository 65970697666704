
.vf-section-wrapper {
  position: relative;
  padding: 10px 0px 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'museo-sans';
}

.vf-section {
  background-color: white;
  width: 100%;
  border: 1px solid rgba(151, 151, 151, 0.25);
  border-radius: 4px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
}

.vf-section .section-header-wrapper {
  font-family: 'Georgia';
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #2e334e;
}

.section-description {
  font-size: 14px;
  color: #595959;
}

.vf-section .section-header-wrapper .section-header {
  display: flex;
  gap: 6px;
  align-items: center;
  flex-grow: 1;
}

.vf-section .section-header-wrapper .section-header-static {
  display: flex;
  gap: 6px;
  align-items: center;
  flex-grow: 1;
  padding-left: 20px;
}

.vf-section .section-header-wrapper div:last-child {
  margin-right: -16px;
}

.vf-section-inner {
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 18px;
  font-weight: 600;
}
.vf-section-inner-blank {
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 18px;
  font-weight: 600;
  padding: 14px 0;
}
