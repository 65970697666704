.container {
    width: 100%;
    overflow: hidden;
    display: flex
}

.tableHeader {
    border: 1px solid rgba(151, 151, 151, 0.25);
}

.tableHeaderCell {
    font-family: 'museo-sans' !important;
    font-style: normal;
    font-weight: 1000 !important;
    font-size: 11px !important;
    line-height: 13px !important;
    color: #2E334E !important;
    background-color: #F7F7F7;
}

.tableRow {
    border-top: 1px solid rgba(151, 151, 151, 0.25);
}

.tableRow:hover {
    background-color: #e4f6f6;
}

.tableRowCell {
    font-family: 'museo-sans' !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #565656 !important;
}

.tableRowCellProgress {
    font-family: 'museo-sans' !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 14px !important;
    color: #565656 !important;
}

.tableRowFirstCell {
    font-family: 'museo-sans' !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #565656 !important;
    padding-right: 0 !important;
}

.pageButtonSelected {
    background-color: #6BAEB4 !important;
    color: white !important;
    font-family: 'museo-sans' !important;
    min-width: 1% !important;
    width: 22px;
    height: 22px;
    padding: 0 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
}

.pageButton {
    background-color: white !important;
    color: #000000 !important;
    font-family: 'museo-sans' !important;
    min-width: 1% !important;
    width: 22px;
    height: 22px;
    padding: 0 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
}