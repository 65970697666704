/* CLAAccordion */

.addProject a {
    font-family: 'museo-sans';
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #6BAEB4;
    text-decoration: none;
}

div.addProject {
    float: right!important;
    display: block;
}

.addProject img {
    vertical-align: auto;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-top: -2px;
    ;
}

.accordionContainer {
    margin-bottom: 44px;
    min-width: 400px;
}

.hasSubtitle2 {
    font-family: 'museo-sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #565656;
    margin-left: 62px;
    margin-bottom: 25px;
}

.noSubtitle2 {
    display: none;
}