.project-form-instance {
  padding: 50px 120px 20px 120px;
  background-color: #F6F6F6;
}

.vf-units {
  color: #9D9A9A;
  font-family: 'museo-sans';
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  overflow-y: auto;
}

.vf-units-label {
  color: #9D9A9A;
  font-family: Georgia;
  font-size: 17px;
  line-height: 19px;
  font-weight: 700;
  min-width: 140px;
}

.vf-form-title {
  color: #000000;
  font-family: Georgia;
  font-size: 21px;
  line-height: 24px;
  font-weight: 700;
}

.vf-form-description {
  margin-top: 9px;
  font-weight: 600;
  font-family: 'museo-sans';
  color: #595959;
  font-size: 14px;
  line-height: 17px;
  max-height: 85px;
  overflow-y: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.vf-form-description-conflict {
  color: #76D2D3;
  text-decoration: underline;
  cursor: pointer;
}
.disable-click {
  color: #9D9A9A;
  pointer-events: none;
  cursor: default;
}
.vf-form-description::-webkit-scrollbar,
.vf-units::-webkit-scrollbar {
  width: 5px;
  width: 5px;
  border: 0.5px solid #E5E5E5;
  border-radius: 2px;
}

.vf-form-description::-webkit-scrollbar-track,
.vf-units::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: none
}

.vf-form-description::-webkit-scrollbar-thumb,
.vf-units::-webkit-scrollbar-thumb {
  background-color: #979797;
  border-radius: 10px;
}

.vf-canvas {
  font-family: 'museo-sans';
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 24px;
  border-radius: 4px;
}

.vf-section-wrapper {
  position: relative;
  padding: 10px 0px 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'museo-sans';
}

.vf-section {
  background-color: white;
  width: 100%;
  border: 1px solid rgba(151, 151, 151, 0.25);
  border-radius: 4px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.vf-section .section-header-wrapper {
  font-family: 'Georgia';
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #2E334E;
}

.vf-section .section-header-wrapper .section-header {
  display: flex;
  gap: 6px;
  align-items: center;
  flex-grow: 1;
}

.vf-section .section-header-wrapper div:last-child {
  margin-right: -16px;
}

.vf-section-inner {
  background-color: transparent;
  width: 100%;
  height: 100%;
  min-height: 15vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 18px;
  font-weight: 600;
}

.vf-section-inner .free-text {
  white-space: pre-line;
  color: #6d6d6d;
  word-break: break-word;
  text-align: justify;
}

.vf-section-inner .row-field {
  display: flex;
  position: relative;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
}

.vf-section-inner .row-field .MuiSelect-icon {
  color: #4B4B4B;
  width: 1.25em;
  height: 1.25em;
  top: calc(50% - 0.625em);
}

.vf-rendered-field-wrapper {
  position: relative;
  padding: 24px;
}

.field__wrapper {
  display: flex; 
  justify-content: center; 
  width: 100%;
}