.radio-control .tooltip-icon {
    margin-left: 7px;
    width: 13px;
    height: 13px;
    color: #2E334E;
}

.radio-control .container-list {
    margin-top: 12px;
}

.radio-control .container-list .list-wrapper {
    min-width: 70px;
}

.radio-control .container-list .list-wrapper label {
    align-items: flex-start;
}

.radio-control .container-list .list-wrapper .other-wrapper {
    flex-direction: row;
    display: flex;
}

.radio-control {
    width: 100%;
}
 