
.scotabds-select {
    font-size: 1rem;
    height: auto;
    font-weight: 600;
    line-height: 1.5; 
}

.scotabds-select .render-values {
    white-space: break-spaces;
}

.scotabds-select .truncate-values {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scotabds-select .tooltip-icon {
    margin-left: 7px;
    width: 19px;
    height: 19px;
    color: #2E334E;
}

.scotabds-select .placeholder {
    color: #cbcbcb;
}

.scotabds-item div {
    padding: 4px 2px;
    white-space: break-spaces;
}

.scotabds-placeholder {
    display: flex;
    width: 100%;

    justify-content: space-between;

    font-family: 'museo-sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
}

.scotabds-transaction {
    display: flex;
    width: 50%;
    padding-left: 15px;
}

.scotabds-AccountBalance {
    display: flex;
    width: 100%;
    padding-left: 3px;
}