.vf-canvas {
  font-family: 'museo-sans';
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 24px;
  border-radius: 4px;
}

.vf-rendered-field-wrapper {
  position: relative;
  padding: 24px;
}

.vf-rendered-field-wrapper>div>.field-overlay {
  background-color: #7dd2d340;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.link-icon {
  cursor: pointer;
  margin-left: 5px;
}

.vf-rendered-field-wrapper>.rendered-field {
  padding: 8px;
}

.vf-rendered-field-wrapper>.highlight-related {
  border-style: dashed;
  border-width: 4px;
  border-color: #49bfc1;
}

.vf-rendered-field-wrapper>.highlight-modified {
  border-style: dashed;
  border-width: 4px;
  border-color: red;
}

.vf-rendered-field-wrapper>.with-link {
  margin-right: 10px;
}

.vf-rendered-field-wrapper>.edit-mode {
  position: relative;
}

.vf-rendered-field-wrapper>.edit-mode>.vf-rendered-field-drag-icon {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 20%;
}

.vf-rendered-field-wrapper>.edit-mode>.vf-rendered-field-drag-icon:hover {
  cursor: pointer;
}

.vf-rendered-field-buttons button:disabled {
  padding-top: 4px;
  padding-bottom: 4px;
}

.vf-rendered-field-buttons> {
  display: flex;
  margin-bottom: 12px;
}

.vf-section-wrapper {
  position: relative;
  padding: 10px 0px 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'museo-sans';
}

.vf-section-wrapper--comm-of-sig-matters {
  position: relative;
  padding: 34px 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'museo-sans';
  font-size: 18px;
  font-weight: 600;
}

.vf-section {
  background-color: white;
  width: 100%;
  border: 1px solid rgba(151, 151, 151, 0.25);
  border-radius: 4px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  /* row-gap: 16px; */
}

.vf-section.view-form-internal-control-section-blank {
  padding: 0
}

.vf-section .section-header-wrapper {
  font-family: 'Georgia';
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #2e334e;
}

.vf-section .section-header-wrapper .section-header {
  display: flex;
  gap: 6px;
  align-items: center;
  flex-grow: 1;
}

.vf-section .section-header-wrapper .section-header-static {
  display: flex;
  gap: 6px;
  align-items: center;
  flex-grow: 1;
  padding-left: 20px;
}

.vf-section .section-header-wrapper div:last-child {
  margin-right: -16px;
}

.vf-section-table-label-wrapper {
  padding: 10px;
}

.vf-section-table-label {
  color: #2e334e !important;
  white-space: 'normal';
  width: 'max-content';
}

.vf-section-formula-label {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #595959;
}

.vf-section-formula-value {
  font-weight: 700;
  font-size: 17px;
  line-height: 32px;
}

.vf-section-blank {
  background-color: transparent;
  border-color: transparent;
}

.vf-section-placeholder {
  background-color: #C4C4C4;
  color: white;
  height: 80px;
  text-align: center;
  vertical-align: middle;
  line-height: 80px;
}

.vf-conclusion {
  color: #000000;
  font-family: Georgia;
  font-size: 21px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 24px;
}

.vf-signoff-optional {
  color: #7DD2D3;
  font-family: 'museo-sans';
  font-weight: 600;
  font-size: 12px;
  line-height: 28px;
}

.vf-signoff-info {
  color: black;
  font-family: 'museo-sans';
  font-weight: 600;
  font-size: 12px;
  line-height: 28px;
}

_communication {
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

_communication_noalign{
  margin: 10px 0;
  width: 100%;
}

_communication_inner {
  width: 100%;
}

.vf-section-blank-wrapper {
  padding: 24px 0;
}

.vf-section-custom-wrapper {
  padding: 0 24px 0px 24px;
}

.field__container {
  background-color: white;
  color: white;
  height: 80px;
  text-align: center;
  vertical-align: middle;
  line-height: 80px;
  padding-top: 11px;
}

.field_wrapper_locked_tooltip_title {
  display: flex;
  flex-direction: row;
  padding: .6rem;
}

.person {
  --avatar-size: 2.8rem;
  color: #565656;
}

.prior_period_dropdown {
  flex-grow: 1;
  width: 18%;
  text-align: left;
}

.add-risk-header-container,
.add-deficiency-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 36px;
}

.add-risk-form-container,
.add-deficiency-form-container {
  display: flex;
  flex-direction: column;
  width: 30%
}

.add-risk-dropdown {
  width: 32.33%;
  padding-right: 20px;
}

.add-risk-dropdown-assertions {
  width: 32.33%;
}

.delete-icon-container {
  width: 1%;
}

.delete-icon {
  width: 24px;
  max-height: 25px;
  padding-top: 1px;
}

.addReportButton {
  margin-left: -6px;
}

.iconStyle {
  color: #7DD2D3;
  font-weight: bold;
}

.textStyle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 12px;
  line-height: 13px;
}

.radio-container {
  width: 20%;
}

.radio-label {
  height: 55px;
}

.header-info {
  display: flex;
}

.add-report-button {
  margin-left: -6px;
}

.add-another-audi-area-text-style-container {
  margin-top: 8px;
}

.add-another-audit-area-text-style {
  font-family: 'museo-sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.delete-icon-inherent-risk {
  width: 24px !important;
}

.radio-group-small-label {
  font-family: 'museo-sans';
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: -3px;
}

.modal-content-outer-container {
  max-height: 560px;
}

.dialog-title-adjustment {
  margin-bottom: -13px;
}

.dialog-title-info {
  font-family: 'museo-sans';
  font-size: 11px;
  margin-left: -3px;
  font-weight: 400;
  width: 30%;
}

.form-info {
  display: flex;
  width: 100%;
}

.form-info>.form-info-label {
  width: 30%;
}

.form-info>.form-info-value {
  width: 70%;
}

.name-input-label {
  color: black !important;
  font-weight: 400 !important;
  font-size: 11px;
  white-space: normal !important;
}

.name-input-label-title {
  font-weight: 400 !important;
  font-size: 11px;
  display: flex !important;
  justify-content: flex-end;
  margin-right: 10px;
}

.section-input-label {
  color: black !important;
  font-weight: 400 !important;
  font-size: 11px;
  white-space: normal !important;
}

.section-input-label-title {
  font-weight: 400 !important;
  font-size: 11px;
  display: flex !important;
  justify-content: flex-end;
  margin-right: 10px;
}

.unitAreaContainer {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
}

.account__assertion__wrapper {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.expand_all_link,
.expand_all_link:hover {
  font-size: 11px;
  font-weight: 600;
  font-family: 'museo-sans';
  text-decoration: none;
  color: #2E334E;
}

.vf-section-table-container {
  overflow: auto;
}

.radio-options-full>div {
  min-width: 100%;
}

.vf-field__freetext-container {
  width: 100%;
}

.communcation_container {
  padding: 0 23px;
  padding-bottom: 23px;
  margin-top: 20px;
}

.communcation_text {
  font-weight: 400;
}

.communcation_main_content {
  margin-top: 15px;
}

.communcation_main_content > div {
  margin-top: 0;
}

.field__wrapper_communication {
  display: flex; 
  justify-content: center; 
  width: 100%;
}

.field__wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}
.field__wrapper_inner {
  width: 100%;
}
.evaluating_container {
  padding: 0 23px;
  padding-bottom: 23px;
  margin-top: 20px;
}

.evaluating_purpose {
  margin-bottom: 10px;
}

.evaluating_objectives {
  margin-bottom: 10px;
}

.evaluating_procedures, .evaluating_common, span, .evaluating_procedures_addition, .evaluating_accumulate_risks {
  font-weight: 400;
}

.evaluating_main_content {
  margin-top: 40px;
}

.evaluating_table {
  margin-top: 15px;
}

.evaluating_section_title {
  font-weight: 700;
}

.evaluating_evaluation {
  margin-top: 40px;
}