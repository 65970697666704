.drawer-container {
    margin: 15px;
    background-color: #ffff;
    border-radius: 5px;
    height: max-content;
}

.drawer-wrapper {
    display: flex;
}

.header-title {
    padding: 25px;
    font-size: 17px;
    font-family: 'Georgia';
    font-weight: bold;
    cursor: default;
    padding: 60px 0px 20px 25px;
}
